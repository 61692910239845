import axios from 'axios';
import {apiUrl} from '@/functions/functions';

export default {
    state: {
        loaded:       false,
        organisation: '',
        domain:       '',
        stub:         '',
        loggedIn:     false
    },

    getters: {

        isLoaded: state => !!state.loaded,

        isLoggedIn: state => !!state.loggedIn,

        getOrganisation: (state) => {

            if (state.organisation) {
                return state.organisation;
            }

            return '';
        }

    },

    mutations: {

        setBrand(state, data)
        {
            state.loaded       = true;
            state.organisation = data.organisation;
            state.domain       = data.domain;
            state.stub         = data.stub;
            state.loggedIn     = data.loggedIn;
        }

    },

    actions: {

        setupBrand({commit})
        {
            let url = apiUrl() + '?action=brand-details'

            axios.get(url).then((response) => {

                let rs = response.data;

                if (rs.status === 'Y') {

                    let data = rs.data;

                    let stub = '';

                    if (window.location.hostname === '127.0.0.1') {
                        stub = window.location.hostname;
                    } else {
                        stub = window.location.hostname.split('.')[0];
                    }

                    if (stub === data.stub) {

                        let brandData = {
                            organisation: data.organisation,
                            domain:       data.domain,
                            stub:         data.stub,
                            loggedIn:     data.loggedIn
                        };

                        commit('setBrand', brandData);

                    }

                }

            }).catch(() => { // Request error
            });

        }

    }

};
