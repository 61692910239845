<template>
    <v-dialog v-model="dialog"
              max-width="400px"
              persistent
    >
        <v-card>
            <v-toolbar
                color="primary"
                dark
            ><strong>Confirmation</strong>
            </v-toolbar>
            <div class="px-4 py-7 pb-0">
                <div v-if="message">
                    {{ message }}
                </div>
                <div v-if="imageSubmitConfirmation" class="multiline-checkbox">
                    <v-checkbox
                        v-model="checked"
                        id="confirmationCheckbox"
                    />
                    <label for="confirmationCheckbox">
                        <div class="confirmation-text">
                            <p>
                                I certify that I have:
                            </p>
                            <ul>
                                <li>
                                    seen the original documentation
                                </li>
                                <li>
                                    that the photograph is a true likeness of the individual
                                </li>
                                <li>
                                    this copy is a true and accurate copy of the original
                                </li>
                            </ul>
                        </div>
                    </label>
                </div>
                <div v-else-if="checkboxMessage" :class="checkboxWrapperClass">
                    <v-checkbox
                        :label="checkboxMessage"
                        v-model="checked"
                    />
                </div>
            </div>
            <v-card-actions class="justify-end">
                <v-btn
                    color="primary"
                    @click="doConfirm"
                    :disabled="buttonDisabled"
                >Confirm
                </v-btn>
                <v-btn
                    text
                    @click="close"
                >Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

export default {
    name: 'ConfirmDialog',

    props: {
        dialog:          Boolean,
        message:         String,
        confirmCallback: Function,
        cancelCallback:  Function,
        checkbox:        Boolean,
        checkboxMessage: String,
        clearCheckbox:   Boolean,
        scenario:        String
    },

    data: () => ({
        checked: false
    }),

    computed: {

        imageSubmitConfirmation()
        {
            return this.scenario === 'imageSubmitConfirmation';
        },

        checkboxWrapperClass()
        {
            if (!this.message) {
                return 'mt-n5';
            }

            return '';
        },

        buttonDisabled()
        {
            if (!this.checkbox) {
                return false;
            }

            return !this.checked;
        }

    },

    updated: function () {

        if (this.clearCheckbox) {
            this.checked = false;
        }

        this.$emit('update:clearCheckbox', false);

    },

    methods: {

        close: function () {
            this.checked = false;
            this.$emit('update:dialog', false);
        },

        doConfirm: function () {
            this.checked = false;
            this.confirmCallback();
        }

    },
}

</script>