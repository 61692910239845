import {apiUrl} from "@/functions/functions";

export default {
    methods: {
        leadingZero(amount)
        {

            let amountStr = amount.toString();

            amountStr = amountStr.padStart(2, '0');

            return amountStr;

        },

        checkVisible(elm, threshold, mode)
        {
            threshold = threshold || 0;
            mode      = mode || 'visible';

            var rect       = elm.getBoundingClientRect();
            var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
            var above      = rect.bottom - threshold < 0;
            var below      = rect.top - viewHeight + threshold >= 0;

            return mode === 'above' ? above : (mode === 'below' ? below : !above && !below);
        },

        goToFirstError: function () {

            window.setTimeout(function () {

                let offset = 30;

                let firstErrorDOM = document.querySelector('.v-input.error--text:first-of-type');

                if (firstErrorDOM !== null) {

                    // It is already visible?
                    let visible = this.checkVisible(firstErrorDOM, offset + 100); // Calculate with top bar

                    if (visible) {
                        return true;
                    }

                    this.$vuetify.goTo(
                        '.v-input.error--text:first-of-type',
                        {
                            offset: offset,
                        },
                    );
                }

            }.bind(this), 100);

        },

        apiUrl: function () {
            return apiUrl();
        }
    },
};
