export function checkRequired()
{
    return [
        v => !!v || 'Required'
    ];
}

function isValidEmail(str)
{
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(str)) {
        return true;
    }

    return false;
}

export function checkEmail()
{
    return [
        v => !!v || 'Rquired',
        v => v && isValidEmail(v) || 'Please provide a valid email'
    ];
}