<template>
    <v-dialog v-model="dialog"
              max-width="400px"
              :persistent="reloadRequired || loginRequired"
    >
        <v-card>
            <v-toolbar
                color="error"
                dark
            ><strong>Error</strong>
            </v-toolbar>
            <div class="px-4 py-7 pb-0">
                {{ errorMessage }}
                <span v-if="reloadRequired">Please refresh the page!</span>
            </div>
            <v-card-actions class="justify-end">
                <v-btn text
                       @click="reloadWindow"
                       v-if="reloadRequired"
                >Refresh
                </v-btn>
                <v-btn text
                       @click="goToLogin"
                       v-if="loginRequired"
                >Log in
                </v-btn>
                <v-btn
                    text
                    @click="close"
                    v-else
                >Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

export default {
    name: 'ErrorDialog',

    props: {
        dialog:         Boolean,
        errorMessage:   String,
        reloadRequired: Boolean,
        loginRequired:  Boolean
    },

    methods: {

        close: function () {
            this.$emit('update:dialog', false);
        },

        reloadWindow: function () {
            window.location.reload();
        },

        goToLogin: function () {
            this.$router.push({
                name: 'Login',
            });
        }

    },
}

</script>