<template>
    <v-card loading
            class="mb-5">
        <div class="py-15 px-4">
            App is loading...
        </div>
    </v-card>
</template>
<script>

import {mapGetters} from 'vuex';

export default {

    name: 'Start',

    mounted()
    {
        if (this.isLoggedIn) {

            this.$router.push({
                name: 'Upload',
            });

        } else {

            this.$router.push({
                name: 'Login',
            });

        }
    },

    computed: {

        ...mapGetters(['isLoggedIn'])

    }

}

</script>