<template>
    <div>
        <v-container>
            <v-card :loading="verifying">
                <v-card-title>Verification</v-card-title>
                <v-card-text>
                    <p v-if="verifying">
                        Verification is in progress
                    </p>
                    <p v-else>
                        {{ this.standardError }}
                    </p>
                </v-card-text>
            </v-card>
        </v-container>
        <v-dialog v-model="errorDialog"
                  max-width="400px"
                  persistent
        >
            <v-card>
                <v-toolbar
                    color="error"
                    dark
                ><strong>Error</strong>
                </v-toolbar>
                <div class="px-4 py-7 pb-0">
                    {{ errorMessage }}
                </div>
                <div class="px-4 py-5 pb-2" style="line-height: 1em;" v-if="fingerprint">
                    <small class="grey--text">Fingerprint: {{ fingerprint }}</small>
                </div>
                <v-card-actions class="justify-end">
                    <v-btn text
                           @click="goToLogin"
                    >Login
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import axios from 'axios';

export default {
    name: 'Verify',

    data: () => ({
        verifying:   true,
        errorDialog: false,
        fingerprint: '',

        errorMessage:  '',
        standardError: 'Verification failed. Please try to reopen the link or try to login again!'
    }),

    mounted()
    {
        this.verify();
    },

    methods: {

        verify: async function () {

            let loginKey = this.$route.query.key;

            if (!loginKey) {
                this.raiseError();
                return false;
            }

            const formData = new FormData();
            formData.append('action', 'login-verify');
            formData.append('loginKey', loginKey);

            await axios.post(this.apiUrl(), formData).then((response) => {

                let rs = response.data;

                // Process response -> Can contain errors
                if (rs.status === 'Y') {

                    this.goToUpload();

                } else {
                    this.raiseError(rs.error, rs.fingerprint);
                }

            }).catch(error => { // Request error
                this.raiseError(error);
            });

        },

        raiseError(error, fingerprint)
        {
            if (!error) {
                error = this.standardError;
            }

            this.errorMessage = error;
            this.fingerprint  = fingerprint;
            this.errorDialog  = true;
            this.verifying    = false;
        },

        goToLogin: function () {
            this.$router.push({
                name: 'Login',
            });
        },

        goToUpload: function () {
            this.$router.push({
                name: 'Upload',
            });
        }

    }
}
</script>
