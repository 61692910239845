<template>
    <div>
        <v-container v-if="!formID">
            <v-card :loading="settingUp">
                <v-card-text>
                    Form is setting up.
                </v-card-text>
            </v-card>
        </v-container>
        <v-container v-else>
            <v-form class="form"
                    ref="imageForm"
                    v-model="formValid"
                    :disabled="resettingForm"
            >
                <v-card :loading="resettingForm">
                    <v-card-text>
                        <v-row class="form-row">
                            <v-col>
                                <v-text-field
                                    label="Your Name"
                                    prepend-icon="mdi-human-male"
                                    v-model="name"
                                    :rules="rules.name"
                                    filled
                                />
                            </v-col>
                        </v-row>
                        <v-row class="form-row">
                            <v-col>
                                <v-text-field
                                    label="Email Address"
                                    prepend-icon="mdi-email"
                                    v-model="email"
                                    type="email"
                                    filled
                                    disabled
                                />
                            </v-col>
                        </v-row>
                        <v-row class="form-row">
                            <v-col>
                                <v-text-field
                                    label="Client Name"
                                    prepend-icon="mdi-human"
                                    v-model="clientName"
                                    :rules="rules.name"
                                    filled
                                />
                            </v-col>
                        </v-row>
                        <v-row class="form-row">
                            <v-col>
                                <v-text-field
                                    label="Reference"
                                    v-model="reference"
                                    prepend-icon="mdi-note-text"
                                    required
                                    filled
                                />
                            </v-col>
                        </v-row>
                        <v-row class="form-row">
                            <v-col class="icon-title">
                                <div class="icon">
                                    <v-icon>mdi-camera</v-icon>
                                </div>
                                <div class="title">Images</div>
                            </v-col>
                        </v-row>
                        <v-row class="form-row">
                            <v-col class="image-uploads icon-padding">
                                <div v-for="image in files"
                                     :key="image.id"
                                     class="image"
                                >
                                    <v-card :loading="image.removing">
                                        <div class="status-icon">
                                            <v-icon v-if="image.removing">mdi-cloud-sync-outline</v-icon>
                                            <v-icon v-else>mdi-cloud-check-outline</v-icon>
                                        </div>
                                        <div class="image-container">
                                            <img :src="image.url"/>
                                        </div>
                                        <v-card-actions class="card-actions">
                                            <div v-if="!image.remote">
                                                <v-btn text
                                                       color="primary"
                                                       @click="uploadFile(image.id)"
                                                       v-if="!image.uploading && !image.remote"
                                                       :disabled="resettingForm"
                                                >Upload
                                                </v-btn>
                                                <v-btn text
                                                       color="primary"
                                                       v-if="image.uploading"
                                                       disabled
                                                >Uploading
                                                </v-btn>
                                                <v-btn text
                                                       color="error"
                                                       @click="removeFile(image)"
                                                       :disabled="resettingForm"
                                                       v-else
                                                >Remove
                                                </v-btn>
                                            </div>
                                            <div v-else>
                                                <v-btn text
                                                       color="error"
                                                       disabled
                                                       v-if="image.removing"
                                                >Removing
                                                </v-btn>
                                                <v-btn text
                                                       color="error"
                                                       @click="removeFile(image)"
                                                       :disabled="resettingForm"
                                                       v-else
                                                >Remove
                                                </v-btn>
                                            </div>
                                        </v-card-actions>
                                    </v-card>
                                    <v-select
                                        :items="imageCategoryOptions"
                                        v-model="image.category"
                                        label="Document Type"
                                        :rules="rules.documentType"
                                        filled
                                        class="mt-2"
                                        clearable
                                    ></v-select>
                                </div>
                                <div class="image add">
                                    <v-card flat>
                                        <label class="add" for="file">
                                            <v-file-input
                                                id="file"
                                                v-model="file"
                                                accept="image/*"
                                                capture="camera"
                                                @change="fileChanged"
                                                hide-input
                                            />
                                            <v-icon class="mt-n1">mdi-plus-circle</v-icon>
                                            <span class="title">Add</span>
                                        </label>
                                    </v-card>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-row class="form-row buttons">
                            <v-col>
                                <div class="icon-padding">
                                    <v-btn
                                        text
                                        class="float-right"
                                        @click="resetForm"
                                        :loading="resettingForm"
                                    >Clear
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        @click="submitForm"
                                        :disabled="resettingForm"
                                    >
                                        Send for signing
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-container>
        <Success
            :dialog.sync="thanks"
            title="Document created"
            message="You will receive a copy of the completed document via email. The link in the email will expire in 72 hours."
        />
        <Error
            :dialog.sync="errorDialog"
            :errorMessage="errorDialogMessage"
            :reloadRequired="errorReloadRequired"
            :loginRequired="errorLoginRequired"
        />
        <Confirm
            :dialog.sync="confirmDialog"
            :confirmCallback="confirmedForm"
            :message="confirmDialogMessage"
            :checkbox="true"
            :clearCheckbox.sync="clearCheckbox"
            scenario="imageSubmitConfirmation"
        />
    </div>
</template>

<script>

import axios from 'axios';

import Error from '@/components/ErrorDialog';
import Success from '@/components/SuccessDialog';
import Confirm from '@/components/ConfirmDialog';

export default {
    name: 'ImageForm',

    components: {
        Error,
        Success,
        Confirm
    },

    data: () => ({
        name:       '',
        email:      '',
        clientName: '',
        reference:  '',

        files: [],

        file: null,

        formValid: true,

        thanks: false,

        errorDialog:         false,
        errorDialogMessage:  '',
        errorReloadRequired: false,
        errorLoginRequired:  false,

        confirmDialog:        false,
        confirmDialogMessage: '',
        confirmed:            false,
        clearCheckbox:        false,

        resettingForm: false,

        formID:    null,
        settingUp: true,

        imageCategoryOptions: [
            'Driving Licence Front',
            'Driving Licence Back',
            'Passport',
            'Proof of address',
            'Other'
        ]
    }),

    mounted()
    {
        this.setupFormID();
    },

    computed: {

        rules: function () {

            let emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            return {
                name: [
                    v => !!v || 'Please provide a name.',
                    v => v && v.length >= 5 || 'Please provide a full name.',
                    v => v
                         && v.trim().indexOf(' ') != -1
                         && !v.trim().trim().endsWith(' ')
                         || 'Please provide a full name.',
                ],

                nameOptional: [
                    v => !v || (v && v.length >= 5) || 'Please provide a full name.',
                    v => !v || (
                                v
                                && v.trim().indexOf(' ') != -1
                                && !v.trim().endsWith(' ')
                            )
                         || 'Please provide a full name.',
                ],

                email: [
                    v => !!v || 'Please provide an email.',
                    v => !v || emailPattern.test(v) || 'Please provide a valid email.',
                ],

                file: [
                    v => !!v || 'Please provide an image file.',
                ],

                documentType: [
                    v => !!v || 'Please select the document type',
                ]
            };

        }

    },

    methods: {

        setupFormID: function () {

            let url = this.apiUrl() + '?action=create-form';

            axios.get(url).then((response) => {

                let rs = response.data;

                if (rs.status === 'Y') {

                    this.formID = rs.data.id;

                    this.name  = rs.data.name;
                    this.email = rs.data.email;

                    Object.entries(rs.data.images).forEach(image => {

                        let img = image[1];

                        img.removing = false;
                        img.remote   = true;

                        this.files.push(img);

                    });

                } else {
                    this.raiseError(rs.error, false);
                }

                this.settingUp = false;

            }).catch(error => { // Request error
                this.raiseError(error, false);
                this.settingUp = false;
            });

        },

        confirmedForm: function () {
            this.confirmDialog = false;
            this.confirmed     = true;
            this.submitForm();
        },

        submitForm: async function () {

            this.$refs.imageForm.validate();

            if (!this.formValid) {
                this.goToFirstError();
                return false;
            }

            let nonUploadedImages = 0;

            // Check for non uploaded image
            Object.entries(this.files).forEach((file) => {

                if (file[1].remote !== true) {
                    nonUploadedImages++;
                }

            });

            if (nonUploadedImages) {
                this.raiseError(
                    'You have ' + nonUploadedImages + ' image' + (nonUploadedImages > 1 ? 's' : '') + ' waiting to upload. Please upload or remove ' + (nonUploadedImages > 1 ? 'them' : 'it') + '.'
                );
                return false;
            }

            // At least one image is required
            if (this.files.length < 1) {
                this.raiseError('Please provide at least one image.');
                return false;
            }

            if (!this.confirmed) {
                this.confirmDialog = true;
                return false;
            }

            this.resettingForm = true;

            const formData = new FormData();
            formData.append('action', 'save-form');
            formData.append('name', this.name);
            formData.append('clientName', this.clientName);
            formData.append('reference', this.reference);

            Object.values(this.files).forEach((image) => {
                formData.append('imageCategories[' + image.id + ']', image.category);
            });

            await axios.post(this.apiUrl(), formData).then((response) => {

                let rs = response.data;

                // Process response -> Can contain errors
                if (rs.status === 'Y') {

                    this.thanks = true;

                    this.resetForm();

                } else {
                    this.raiseError(rs.error);
                }

            }).catch(error => { // Request error
                this.raiseError(error);
            });

            this.resettingForm = false;
            this.confirmed     = false;

        },

        resetForm: async function () {
            this.resettingForm = true;
            this.clearCheckbox = true;

            let url = this.apiUrl() + '?action=reset-form'

            await axios.get(url).then((response) => {

                let rs = response.data;

                if (rs.status === 'Y') {

                    this.clientName = '';
                    this.reference  = '';
                    this.file       = null;

                    this.files = [];

                    this.$refs.imageForm.resetValidation();

                    // New formID
                    this.setupFormID();

                } else {
                    this.raiseError(rs.error, true);
                }

            }).catch(error => { // Request error
                this.raiseError(error, true);
            });

            this.resettingForm = false;
            this.confirmed     = false;
        },

        raiseError: function (error, reload) {

            if (!error) {
                error = 'Fatal error. Please try again.';
            }

            let loginError = false;

            if (typeof error === 'string' && error.search(/Session Expired/i) > -1) {
                loginError = true;
            }

            this.errorLoginRequired = false;

            if (loginError) {
                this.errorLoginRequired = true;
            } else {

                if (reload) {
                    this.errorReloadRequired = true;
                } else {
                    this.errorReloadRequired = false;
                }

            }

            this.errorDialog        = true;
            this.errorDialogMessage = error;

        },

        fileChanged: function () {

            if (this.file !== null) {

                let file = this.file;

                // Check if the file is an image.
                if (file.type && file.type.indexOf('image') === -1) {
                    alert('File is not an image. (' + file.type + ')');
                    return false;
                }

                const reader = new FileReader();

                reader.addEventListener('load', (event) => {

                    let id = Date.now();

                    let image = {
                        id:         id,
                        name:       file.name,
                        size:       file.size,
                        type:       file.type,
                        url:        event.target.result,
                        fileObject: file,
                        category:   '',
                        uploaded:   false,
                        uploading:  false,
                        remote:     false
                    };

                    this.file = null;

                    this.files.push(image);

                    this.uploadFile(id);

                });

                reader.readAsDataURL(file);

            }

        },

        removeFile: async function (image) {

            let fileIndex = null;

            Object.entries(this.files).forEach((file, index) => {

                if (file[1].id === image.id) {
                    fileIndex = index;
                }

            });

            if (!image.remote) { // Image is not uploaded yet to the server
                this.files.splice(fileIndex, 1);
                return true;
            }

            this.files[fileIndex].removing = true;

            const formData = new FormData();
            formData.append('action', 'remove-image');
            formData.append('imageID', image.id);

            await axios.post(this.apiUrl(), formData).then((response) => {

                let rs = response.data;

                this.files[fileIndex].removing = false;

                // Process response -> Can contain errors
                if (rs.status === 'Y') {
                    this.files.splice(fileIndex, 1);
                } else {
                    this.raiseError(rs.error);
                }

            }).catch(error => { // Request error

                this.raiseError(error);

                this.files[fileIndex].removing = false;

            });

        },

        uploadFile: async function (id) {

            let image = null;

            Object.entries(this.files).forEach((file, index) => {

                if (file[1].id === id) {
                    image = this.files[index];
                }

            });

            if (image !== null) {

                image.uploading = true;

                const config = {
                    /*onUploadProgress: function (progressEvent) {
                        this.uploadPercentage = parseInt(Math.round(progressEvent.loaded / progressEvent.total) * 100);
                    }.bind(this),
                    */headers: {
                        'content-type': 'multipart/form-data'
                    }
                }

                const formData = new FormData();
                formData.append('action', 'add-image');
                formData.append('file', image.fileObject);

                await axios.post(this.apiUrl(), formData, config).then((response) => {

                    let rs = response.data;

                    // Process response -> Can contain errors
                    if (rs.status === 'Y') {

                        image.id     = rs.data.id;
                        image.url    = rs.data.url;
                        image.remote = true;

                    } else {
                        this.raiseError(rs.error);
                    }

                    image.uploading = false;

                }).catch(error => { // Request error

                    this.raiseError(error);

                    image.uploading = false;

                });

            }

        },

        cancelUpload: function (id) {

            Object.entries(this.files).forEach((file, index) => {

                if (file[1].id === id) {
                    this.files[index].uploading = false;
                }

            });

        },

        getCookie: function (cname) {

            let name          = cname + '=';
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca            = decodedCookie.split(';');

            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];

                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }

                if (c.indexOf(name) == 0) {

                    let value = c.substring(name.length, c.length);

                    value = value.replace('+', ' ');

                    return value;

                }
            }

            return '';

        }

    }
}
</script>
