import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import Theme from './themes/standard'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: Theme.light,
            dark: Theme.dark
        },
        dark: false
    },
});
