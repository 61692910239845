import Vue from 'vue'
import VueRouter from 'vue-router'
import Start from '../views/Start/Start'
import LoginForm from '../views/Auth/LoginForm'
import Verify from '../views/Auth/Verify'
import ImageForm from '../views/ImageUpload/ImageForm'
import NotFound from '../views/Errors/404'

Vue.use(VueRouter)

const routes = [
    {
        path:      '/',
        name:      'Start',
        component: Start
    },
    {
        path:      '/login',
        name:      'Login',
        component: LoginForm
    },
    {
        path:      '/login-verify',
        name:      'Verify',
        component: Verify
    },
    {
        path:      '/upload',
        name:      'Upload',
        component: ImageForm
    },
    {
        path:      '*',
        name:      'NotFound',
        component: NotFound
    },
]

const router = new VueRouter({
    routes,
    mode: 'history',
    base: ''
})

export default router
