import Vuex from 'vuex';
import Vue from 'vue';

import settings from './modules/settings';

Vue.use(Vuex);

export default new Vuex.Store({

    modules: {
        settings
    },

    state: {},

    getters: {},

    actions: {},

    mutations: {},

});
