<template>
    <v-dialog v-model="dialog" max-width="400px">
        <v-card>
            <v-toolbar
                color="success"
                dark
            >{{ (title ? title : 'Success') }}
            </v-toolbar>
            <div class="px-4 py-7 pb-0">
                {{ message }}
            </div>
            <v-card-actions class="justify-end">
                <v-btn
                    text
                    @click="close"
                >Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

export default {
    name: 'ErrorDialog',

    props: {
        dialog:  Boolean,
        title:   String,
        message: String
    },

    methods: {

        close: function () {
            this.$emit('update:dialog', false);
        }

    },
}

</script>