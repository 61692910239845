import colors from 'vuetify/lib/util/colors'

export default {
    light: {
        primary: {
            base: colors.lightBlue.lighten1
        },
        secondary: {
            base: colors.blueGrey.darken2
        },
        error: colors.red.darken2,
        warning: colors.orange.darken2
    },

}
