<template>
    <v-app id="app">
        <template v-if="!isLoaded">
            <v-container>
                <v-card :loading="loading">
                    <v-card-title>IDSense</v-card-title>
                    <v-card-text v-if="loading">
                        Loading...
                    </v-card-text>
                    <v-card-text v-else>
                        The app couldn't be loaded.
                    </v-card-text>
                </v-card>
            </v-container>
        </template>
        <template v-else>
            <v-app-bar app>
                <v-toolbar-title><strong>IDSense</strong> - {{ getOrganisation }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="logout" v-if="logoutAllowed">
                    <v-icon>mdi-logout</v-icon>
                </v-btn>
            </v-app-bar>
            <v-main>
                <router-view/>
                <div class="brand">
                    <a href="https://www.signaturesense.com" target="_blank">
                        Powered by SignatureSense.com
                    </a>
                </div>
            </v-main>
        </template>
    </v-app>
</template>

<script>

import '@/sass/app.scss';

import {mapActions, mapGetters} from 'vuex';
import axios from "axios";

export default {

    name: 'App',

    data: () => ({
        loading:     true,
        brandLoaded: false
    }),

    mounted()
    {
        this.setupBrand();

        this.loading = false;
    },

    computed: {

        ...mapGetters(['getOrganisation', 'isLoaded']),

        logoutAllowed()
        {
            return !(this.$route.name === 'Login');
        }

    },

    methods: {

        ...mapActions(['setupBrand']),

        logout: function () {

            let url = this.apiUrl() + '?action=logout';

            axios.get(url).then(() => {

                this.$router.push({
                    name: 'Login',
                });

            }).catch();

        }

    }

}
</script>