<template>
    <div>
        <v-container>
            <v-form class="form"
                    ref="imageForm"
                    v-model="formValid"
                    :disabled="submittingForm"
                    @submit.prevent="submitForm"
            >
                <v-card :loading="submittingForm">
                    <v-card-title>Login</v-card-title>
                    <v-card-text class="mt-2">
                        <v-row class="form-row">
                            <v-col>
                                <v-text-field
                                    label="Email Address"
                                    v-model="email"
                                    type="email"
                                    filled
                                    :rules="rules.email"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-row class="form-row buttons mt-n10">
                            <v-col>
                                <div class="inline-padding">
                                    <v-btn
                                        color="primary"
                                        :disabled="submittingForm"
                                        type="submit"
                                    >
                                        Log in
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-container>
        <Success
            :dialog.sync="thanks"
            message="Login link has been sent. Please check your inbox."
            />
        <Error
            :dialog.sync="errorDialog"
            :errorMessage="errorDialogMessage"
            :reloadRequired="errorReloadRequired"
        />
    </div>
</template>

<script>

import axios from 'axios';

import Error from '@/components/ErrorDialog';
import Success from '@/components/SuccessDialog';

import {
    checkEmail
} from '@/utils/validations'

export default {
    name: 'LoginForm',

    components: {
        Error,
        Success
    },

    data: () => ({
        email: '',

        formValid: true,

        submittingForm: false,

        thanks: false,

        errorDialog:         false,
        errorDialogMessage:  '',
        errorReloadRequired: false
    }),

    mounted()
    {
    },

    computed: {

        rules: function () {

            return {
                email: checkEmail()
            };

        }

    },

    methods: {

        submitForm: async function () {

            this.$refs.imageForm.validate();

            if (!this.formValid) {
                this.goToFirstError();
                return false;
            }

            this.submittingForm = true;

            const formData = new FormData();
            formData.append('action', 'login');
            formData.append('email', this.email);

            await axios.post(this.apiUrl(), formData).then((response) => {

                let rs = response.data;

                // Process response -> Can contain errors
                if (rs.status === 'Y') {

                    this.thanks = true;

                } else {
                    this.raiseError(rs.error);
                }

            }).catch(error => { // Request error
                this.raiseError(error);
            });

            this.submittingForm = false;

        },

        raiseError: function (error) {
            this.errorDialog        = true;
            this.errorDialogMessage = error;
        }

    }
}
</script>
